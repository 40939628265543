@import '~intro.js/introjs.css';

.introjs-skipbutton {
  visibility: hidden !important;
}

.introjs-tooltiptext {
  font-family: Graphik, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  padding: 20px 0 16px 0;
  text-align: start;
  margin-top: -24px;
  overflow-wrap: break-word;
}

.introjs-tooltip .introjs-tooltipbuttons .introjs-button,
.introjs-button {
  cursor: pointer;
  color: var(--ocean-blue);
  font-family: Graphik, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  background: none;
  border: none;
  padding: 0;
}

.introjs-tooltip .introjs-button:focus {
  box-shadow: none;
  border: none;
}

.introjs-tooltip .introjs-tooltipbuttons {
  border-top-color: transparent;
  padding: 0;
  display: flex;
}

.introjs-tooltip {
  padding: 20px 24px;
  min-width: 200px;
  border-radius: 12px;
}

.introjs-tooltip .introjs-tooltip-header {
  display: none !important;
}

.introjs-helperLayer {
  box-shadow:
    black 0 0 0 0,
    rgba(33, 33, 33, 0.5) 0 0 0 5000px !important;
  border-radius: 12px !important;
}
